import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Carousel, Row, Col, Card } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import { Link } from 'gatsby'
import ReactMarkdown from "react-markdown"


const PropertyOffice = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allStrapiOffices {
          edges {
            node {
              Address
              Email
              Office_Name
              Phone
              Office_Id
              Office_Image {
                publicURL
              }
              URL
            }
          }
        }
    }
  `);



  return (
          <>
          {data.allStrapiOffices.edges.map(({node}, key) => {
            return <>
              {node.Office_Id == props.office_id &&
                <Card>
                  <Row className="align-items-center">
                    <Col md={6} lg={12}>
                      <Card.Img variant="top" src={node.Office_Image.publicURL} alt="store" />
                    </Col>
                    <Col md={6} lg={12}>
                      <Card.Body>

                        <h6><Link to={`/contact-west-yorkshire-estate-agents/${node.URL}`}>{node.Office_Name}</Link></h6>
                        <p><ReactMarkdown source={node.Address} /></p>
                        <p><a href={`tel:+${node.Phone}`}>{node.Phone}</a> / <a href="/contact">Email Office</a></p>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              }
            </>
          })}
          </>
  )
}

export default PropertyOffice
