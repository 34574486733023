import React from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';

/** 
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import PropertyInfo from '@components/property-info/property-info';
import PropertyDetailIntro from '@components/property-detail-intro/property-detail-intro';
import PropertyRecommend from '@components/property-recommend/property-recommend';
import SEO from "../components/seo"


import Logo from '@components/header/assets/img/logo.svg';

class PropertyDetails extends React.Component {

  // State will apply to the posts object which is set to loading by default
  state = {
    posts: [],
    isLoading: true,
    errors: null
  };


  // Now we're going to make a request for data using axios
  getPosts(id) {
    axios
      // This is where the data is hosted
      // TODO below url need to update in env file
      .get('https://holroyds-strapi.q.starberry.com/properties/'+id)
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          posts: response.data,
          isLoading: false
        });
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  let property_slug = url.split('-');
  let id = property_slug[property_slug.length-1];
    this.getPosts(id);
  }
  // Putting that data to use
  render() {
    const { isLoading, posts } = this.state;

    const metaTitle = posts.title + ' in ' + posts.display_address

    const metaDesc = 'Book a viewing for this ' + posts.title + ' in ' +  posts.display_address + ' with Holroyds at ' + posts.price +'. Contact Holroyds, and get assisted for all your property needs.'

    return (
      <>
        {!isLoading ? (
          <>
            <Layout propDetailsData={posts}>
              <Helmet 
                bodyAttributes={{
                  class: 'property-details templates-property-details-js'
                }}
              />
              <SEO title={metaTitle} description={metaDesc} />
              
              <PropertyDetailIntro title={posts.display_address} price={posts.price} price_qualifier={posts.price_qualifier} description={posts.description} images={posts.images} epc_images={posts.epc_images} propertyid={posts.source_reference} name={posts.negotiator_details.name} email={posts.negotiator_details.email} telephone={posts.negotiator_details.telephone} negotiator_id={posts.negotiator_details.id}/>

              <PropertyInfo long_description={posts.long_description} latitude={posts.latitude} longitude={posts.longitude} Address={posts.display_address} office_id={posts.office_id}  virtual_tour={posts.virtual_tour} floorplan={posts.floorplan} room_details={posts.room_details} search_type={posts.search_type}/>

              <PropertyRecommend/>
            </Layout>
          </>
        ) : (
          <div className="property-loading-screen">
            <Logo />
              <p>Loading...</p>
          </div>
          
        )}
      </>
    );
  }
}
export default PropertyDetails;