import React, { useState, useEffect, useRef } from 'react';
import Modal from "react-modal"
import ModalTiny from "react-bootstrap/Modal"


const VirtualTour = (props) => {

  const [modalVideoOpen,setVideoOpen] = React.useState(false);
  const openVideoModal = () => {
    setVideoOpen(true);
  }
  const closeVideoModal = () => {
    setVideoOpen(false);
  }

  if(props.cta == "Floorplans") {
    var classname = "floorplan_modal"
  }
  else
  {
    var classname = ""    
  }


  return (
  <>
    <a onClick={openVideoModal}  className="nav-link">{props.cta}</a>
    <ModalTiny show={modalVideoOpen} onHide={closeVideoModal} className={`video-modal ${classname}`}>
      <ModalTiny.Header closeButton>
        <ModalTiny.Title>{props.title}</ModalTiny.Title>
      </ModalTiny.Header>
      <ModalTiny.Body>
        {props.cta != "Floorplans"  &&
        <iframe width="100%" height="500" src={props.src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyrospicture-in-picture" allowfullscreen></iframe>
        }
        {props.cta == "Floorplans"  &&
        <img src={props.src} />
        }
      </ModalTiny.Body>
    </ModalTiny>
          </>

  )
}

export default VirtualTour;
