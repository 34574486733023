import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import classNames from 'classnames';

import VirtualTour from '@components/property-info/virtual-tour';

/**
 * Assets
 */
import './assets/styles/_index.scss';

const links = [
  {
    text: 'Details',
    url: '#details',
    target: '',
    isSpy: true
  },
  {
    text: 'Map',
    url: '#map-holder',
    target: '',
    isSpy: true
  },
  {
    text: 'Virutal Tour',
    url: '#virtual-tour',
    target: 'tour',
    isSpy: true
  },
  {
    text: 'Mortgages',
    url: '#mortgages',
    target: 'mortgages',
    isSpy: true
  },
  {
    text: 'Floorplans',
    url: '#floorplans',
    target: 'floorplan',
    isSpy: true
  }
];

function getOffset( el ) {
  let _x = 0;
  let _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

const StickyNav = (props, {active, className}) => {
  const scrollHandle = (event) => {
    event.preventDefault();
    const offestTop = getOffset(document.querySelector(event.target.getAttribute('href'))).top;
    const stickyHeight = document.querySelector('.sticky-nav').clientHeight;
    window.scrollTo({
      top: offestTop - stickyHeight,
      behavior: 'smooth'
    });
  }

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <div className={classNames('sticky-nav sticky-top property-sticky', className)}>
      <Container>
        <Scrollspy
          items={["details", "map-holder", "virtual-tour", "mortgages", "floorplans", "brochure", "print"]}
          offset={-96}
          componentTag={Nav}
          currentClassName="is-active"
        >
            {links.map((link, ind) => (
              <>
              {link.target === 'tour' &&
              <>
              {props.virtual_tour != null &&
              <>
                <VirtualTour title="Virtual Tour" cta={link.text} src={props.virtual_tour}/>
              </>
              }
              </>
              }

              {link.target === 'floorplan' &&
              <>
              {props.floorplan != "" &&
              <>
                <VirtualTour title="Floor Plans" cta={link.text} src={props.floorplan}/>
              </>
              }
              </>
              }

              {link.target === 'mortgages' &&
              <>
              {props.search_type === "sales" &&
              <Nav.Link key={ind} href={link.url} onClick={link.isSpy ? scrollHandle : ''}>{link.text}</Nav.Link>
              }
              </>
              }

              {link.target !== '_blank' && link.target !== 'tour' && link.target !== 'floorplan' && link.target !== 'mortgages' &&
              <Nav.Link key={ind} href={link.url} onClick={link.isSpy ? scrollHandle : ''}>{link.text}</Nav.Link>
              }

              </>
            ))}
              <a href={`/property-detail-print/${url.split("/").pop()}`} target="_blank" className="nav-link" >Print</a>
        </Scrollspy>
      </Container>
    </div>
  )
}

export default StickyNav;
