import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Carousel, Row, Col, Card } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import { Link } from 'gatsby'
import ReactMarkdown from "react-markdown"


const PropertyNegotiator = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allStrapiTeams(filter: {Publish: {eq: true}}) {
        edges {
          node {
            Name
            Image {
              publicURL
            }
            Phone
            Negotiator_Id
          }
        }
      }
    }
  `);

const no_image = "/images/no-team.png"


  return (
          <>
          {data.allStrapiTeams.edges.map(({node}, key) => {
          if(node.Image == null) {
            var image = no_image;
          }
          else
          {
            var image = node.Image.publicURL;
          }
            return <>
              {node.Negotiator_Id == props.negotiator_id &&
                <Row>
                  <Col className="col-auto">
                    <img src={image} alt="avatar" className="img-60"/>
                  </Col>
                  <Col>
                    <p>Speak with {node.Name} on<br/> <a href={`tel:+${node.Phone}`}>{node.Phone}</a> or <Link to="/contact">email</Link> </p>
                  </Col>
                </Row>
              }
            </>
          })}
          </>
  )
}

export default PropertyNegotiator
