import React, { useState } from 'react';
import { Container, Row, Col, Button, Accordion, Tabs, Tab, Form, Card } from 'react-bootstrap';
import classNames from 'classnames';
import ReactMarkdown from "react-markdown"
import { Link } from 'gatsby'

import MortgageCalc from '@components/calculators/mortgagecalc/';
import StampdutyCalc from '@components/calculators/stampdutycalc/';

/**
 * Asset
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import StickyNav from '@components/sticky-nav/sticky-nav';
import LocRatingMap from '@components/map/loc-rating-map';
import PropertyAccordion from '@components/property-info/accordion';
import { MapMarkerInfoBox } from '@components/map/map-marker';
import PropertyOffice from '@components/property-info/property-office';
import VirtualTour from './virtual-tour';

const accordions = [
  {
    title: `Entrance Hall`,
    content: <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita sapiente repellat tempore doloribus aut dolorum aperiam non molestias magnam! Asperiores quasi culpa sequi sint iste obcaecati corporis dignissimos eveniet reprehenderit!</p>
  },
  {
    title: `W.C. Cloakroom`,
    content: <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita sapiente repellat tempore doloribus aut dolorum aperiam non molestias magnam! Asperiores quasi culpa sequi sint iste obcaecati corporis dignissimos eveniet reprehenderit!</p>
  },
  {
    title: `Lounge (5.70m x 4.88m (18'8" x 16'0"))`,
    content: <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita sapiente repellat tempore doloribus aut dolorum aperiam non molestias magnam! Asperiores quasi culpa sequi sint iste obcaecati corporis dignissimos eveniet reprehenderit!</p>
  },
]

const PropertyInfo = (props, {className}) => {
  const [active, setActive] = useState('+ Read More');
  const [form, setForm] = useState({
    value: '',
    deposit: '',
    rate: '',
    duration: ''
  })
  const handleAccordionClick = () => {
    if(active === '+ Read More'){
      setActive('- Read Less')
    } else {
      setActive('+ Read More')
    }
  }
  const handleChange = (event) => {
    const value =  event.target.value;
    const name = event.target.getAttribute('name');
    setForm({
      ...form,
      [name]: value
    })
  }

  const url = typeof window !== 'undefined' ? window.location.href : '';


  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat']  = props.latitude;        
  mapItem['lng']  = props.longitude;
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox(props.Address, mapMarkerImageShowFlag);
  // console.log( mapItemHtml );
  mapItem['html'] = mapItemHtml;
  mapItems.push( mapItem );

  const features = props.room_details

  const myJSON = JSON.stringify(features, null, 4);

  // console.log(features);

  if(props.long_description.length < 400){

  var long_desc_first_part = props.long_description;
  var long_desc_second_part = "";
  }
  else
  {

  var long_desc_first_part = props.long_description.split(/\s+/).slice(0,45).join(" ");

  var long_desc_second_part = props.long_description.split(/\s+/).slice(45).join(" ");
  }

  return (
    <div className={classNames('property-info', className)}>
      <StickyNav virtual_tour={props.virtual_tour} floorplan={props.floorplan} search_type={props.search_type}/>
      <Container className="details">
        <Row>
          <Col lg={7}> 
            <div id="details">
              {props.room_details != null &&
              <>
              <h4>Property Features</h4>
              <Row className="description">
                <Col md={12} className="property-features">
                  {props.room_details != null &&
                  <>
                  {props.room_details.map((item) =>
                  <>
                  {item.Name != null &&
                    <p>– {item.Name} </p>
                  }
                  </>
                  )}
                  </>
                  }
                </Col>
              </Row>
              </>
              }

              {long_desc_first_part != null && long_desc_first_part != "" &&
              <>
              <h4>Description</h4>
              <p><ReactMarkdown source={long_desc_first_part} /></p>
              </>
              }
              {props.long_description.length > 400 &&
              <Accordion defaultActiveKey="0" className="no-border--bottom">
                <Accordion.Toggle variant="link" eventKey="1" className="custom-link" onClick={handleAccordionClick}>
                  {active}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <p><ReactMarkdown source={long_desc_second_part} /></p>
                </Accordion.Collapse>
              </Accordion>
              }
              {props.room_details != null &&
              <>
                <div className="room-details">
                  <h4>Room Details</h4>
                  <div className="room-details__accordions">
                    {props.room_details.map((item, ind) => <PropertyAccordion key={ind} item={item} />)}
                  </div>
                </div>
              </>
              }

            </div>
            {props.search_type == "sales" &&
            <div id="mortgages">
              <Tabs>
                <Tab eventKey="calculator" title="Mortgage Calculator">
                  <p>Use our calculator to work out potential mortgage repayments. Please note, this calculator is for general interest only and must not be relied on.</p>
                  <MortgageCalc propertyPrice="4500000" />
                </Tab>
                <Tab eventKey="stamp" title="Stamp Duty">
                  <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus, quos in itaque obcaecati a dicta quo dolorem ratione, sapiente reprehenderit eveniet nisi maiores consequuntur nihil, facere ipsum neque sed ex?</p>
                  <StampdutyCalc propertyValue="10000000" propertyType="residential" buyerType="first" />
                </Tab>
              </Tabs>
            </div>
            }
          </Col>
          <Col lg={{span: 4, offset: 1}} className="align-self-start sidebar-sticky">
            <PropertyOffice office_id={props.office_id}/>
            <Card>
              <Card.Body>
                <div className="socials">
                  <h5>Share this Property</h5>
                  <Link to="/contact">
                    <img src="/images/icon-email.png" alt="email" width="44" height="44"/>
                  </Link>
                  <a href={`//www.facebook.com/sharer/sharer.php?u=${url}`} rel="noreferrer" target="_blank">
                    <img src="/images/icon-facebook.png" alt="facebook" width="44" height="44"/>
                  </a>
                  <a href={`//twitter.com/share?text=${props.Address}&url=${url}&via=holroydsuk`} rel="noreferrer" target="_blank">
                    <img src="/images/icon-twitter.png" alt="twitter" width="44" height="44"/>
                  </a>
                  <a href="http://instagram.com" rel="noreferrer" target="_blank">
                    <img src="/images/icon-instagram.png" alt="instagram" width="44" height="44"/>
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <LocRatingMap data={mapItems} />
    </div>
  )
}

export default PropertyInfo;
