import React from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/**
 * Components
 */
import Property from '@components/property/property';

const PropertyRecommend = ({className}) => {
  return (
    <div className={classNames('overflow-hidden', className)}>
      <div className="property-recommend">
        <Container>
          <h4 className="text-md-center">Other properties that may appeal to you</h4>
          <p className="text-md-center">Need help? <a href="/">Get in touch</a> and we’ll go above and beyond to find you your ideal property</p>
          <Slider
            slidesToShow={3}
            responsive={[
              {
                breakpoint: 980,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000
                }
              }
            ]}
          >
            <Property property={{
                image: '/images/p1.jpg',
                price: '£510,000',
                location: 'Bingley, West Yorkshire',
                description: '4 bedroom house for sale'
              }} 
            />
            <Property property={{
                image: '/images/p2.jpg',
                price: '£510,000',
                location: 'Bingley, West Yorkshire',
                description: '4 bedroom house for sale'
              }} 
            />
            <Property property={{
                image: '/images/p3.jpg',
                price: '£510,000',
                location: 'Bingley, West Yorkshire',
                description: '4 bedroom house for sale'
              }} 
            />
          </Slider>
        </Container>
      </div>
    </div>
  )
}

export default PropertyRecommend;
