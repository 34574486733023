import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import BookAViewing from '@components/reapit-widget/book-a-viewing';
import ReadMoreAndLess from 'react-read-more-less';

/**
 * Assets
 */
import HeartIcon from '@static/svg/heart-icon2.svg';

/**
 * Components
 */
import Icon from '@components/icon/icon';
import PropertySlider from '@components/property-slider/property-slider';
import Relative from '@components/relative/relative';
import PropertyNegotiator from '@components/property-info/property-negotiator';


const PropertyDetailintro = (props,{className}) => {
  const handleBack = () => window.history.back();

  return (
    <Relative className={classNames(className)} background="#ffffff">
      <Container className="property-intro">
        <Row>
          <Col lg={{span: 7, offset: 1}} className="order-lg-last">
            <PropertySlider images={props.images} epc_images={props.epc_images}/>
          </Col>
          <Col lg={4} className="align-self-center pt-5 pb-5">
            <div className="property-intro__text">
              <div className="back-link" onClick={handleBack} onKeyPress={handleBack} role="presentation">&lt; Back to Search</div>
              <h4>{props.title}</h4>
              <div className="property-price">£{props.price.toLocaleString()} {props.price_qualifier || ''}</div>
              <p>
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={140}
                  readMoreText="read more"
                  readLessText="read less"
              >
                  {props.description}
              </ReadMoreAndLess>
              </p>
            </div>
            <Row className="d-sm-flex book">
              <Col>
                <BookAViewing class="primary-button btn btn-primary" propertyid={props.propertyid} />
              </Col>
              <Col xs="auto">
                <Icon>
                  <HeartIcon />
                </Icon>
              </Col>
            </Row>
            <PropertyNegotiator negotiator_id={props.negotiator_id}/>
          </Col>
        </Row>
      </Container>
    </Relative>
  )
}

export default PropertyDetailintro;
