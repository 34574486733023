import React, { useState, Fragment } from 'react';
import { Accordion } from 'react-bootstrap';
import classNames from 'classnames';

const PropertyAccordion = ({item, key, children}) => {
  const [active, setActive] = useState(false);
  const handleClick = () => setActive(!active);
  return (
    <Fragment>
      {item.Name != null &&
      <>
      {item.Size == null &&
      <div className="accordion">
        <span className="no-content">{item.Name}</span>
      </div>
      }
      {item.Size != null &&
      <Accordion>
        <Accordion.Toggle variant="link" eventKey="1" className={classNames({'is-open': active})} onClick={handleClick}>
          {item.Name}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <>{item.Size}</>
        </Accordion.Collapse>
      </Accordion>
      }
      </>
      }
    </Fragment>
  )
}

export default PropertyAccordion

