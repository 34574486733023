import React from 'react';
import classNames from 'classnames';

const Relative = (props) => (
  <div className={classNames('is-relative', props.className)} style={{backgroundColor: props.background}}>
    {props.children}
  </div>
)


export default Relative;
